<script setup lang="ts">
import { ref, defineAsyncComponent, defineProps, watch } from "vue";
import { useOperationStore } from "@/stores/operation";
import LoadingView from "@/views/default/LoadingView.vue";

const operation = useOperationStore();
const DynamicComponent = ref();
const HeaderComponent = ref();
const FooterComponent = ref();

const props = defineProps({
  file: {
    type: String,
    required: true,
  },
});

const vueIsAvailable = async (idgame: string) => {
  try {
    await import(`@/views/${idgame}/${props.file}.vue`);
    return true;
  } catch (error) {
    return false;
  }
};

const getComponentByDomain = async (
  idgame: string,
  name: string,
  vueFolder: string
) => {
  let folder = idgame;
  const exist = await vueIsAvailable(idgame);
  if (!exist) {
    folder = "default";
  }

  return defineAsyncComponent({
    loader: () => {
      return import(`@/${vueFolder}/${folder}/${name}.vue`);
    },
    loadingComponent: LoadingView,
    delay: 1000,
  });
};

const updateDynamicComponents = async () => {
  DynamicComponent.value = await getComponentByDomain(
    operation?.operationData?.idgame ?? "",
    props.file,
    "views"
  );
  HeaderComponent.value = await getComponentByDomain(
    operation?.operationData?.idgame ?? "",
    "HeaderView",
    "components"
  );
  FooterComponent.value = await getComponentByDomain(
    operation?.operationData?.idgame ?? "",
    "FooterView",
    "components"
  );
};

watch(
  () => props.file,
  () => {
    updateDynamicComponents();
  },
  { immediate: true }
);

import "@/assets/2024-Tecnifibre-209/styles/main.scss";
</script>

<template>
  <div class="content_wrapper" v-if="operation?.operationData">
    <HeaderView :is="HeaderComponent" />
    <main>
      <transition name="fade">
        <component :is="DynamicComponent" />
      </transition>
    </main>
    <FooterView :is="FooterComponent" />
  </div>
</template>
