import type { OperationResult, Custom, Forms, Files } from "@/libs/api";
import { fetchOperation } from "@/libs/api";
import { defineStore } from "pinia";

export const useOperationStore = defineStore({
  id: "operation",
  state: () => ({
    operationData: null as OperationResult | null,
    customData: undefined as Custom | null | undefined,
    fileData: undefined as Files | null | undefined,
    formsData: undefined as Forms | null | undefined,
  }),
  getters: {
    getOperationData: (state) => {
      return state.operationData;
    },
    getCustomData: (state) => {
      return state.operationData?.custom;
    },
    getFilesData: (state) => {
      return state.operationData?.files;
    },
    getFormsData: (state) => {
      const forms = state.operationData?.forms ?? [];
      if (Array.isArray(forms)) {
        return forms.filter(
          (form: {
            _id: string;
            type: string;
            lang: string;
            line_item: boolean;
          }) => form.type === "CREATE"
        );
      }
      return [];
    },
  },
  actions: {
    async getOperation() {
      this.operationData = await fetchOperation();
    },
  },
});
